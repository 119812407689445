import React from 'react'


import Layout from '../components/Layout';
import SEO from '../components/seo';
import IndexStyle from './index.module.css';
import cover from '../assets/company-logo-og.jpg';
import comLogo from '../assets/company-logo.png';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { add_to_cart } from '../redux/cart.redux';
import { navigate } from '@reach/router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IndexTab from '../components/indexTab';
import ScrollAnimation from 'react-animate-on-scroll';
import { SBoardObj } from '../../SBoard_Products';
import { MOBandObj } from '../../Global_Config';

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {value: 0, isMobile: true}
    this.nextVideo = this.nextVideo.bind(this)
    this.handleTrackEvent = this.handleTrackEvent.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }
  handleChange = (event, newValue) => {
    var evt = ""
    switch (newValue) {
      case 0:
        evt = "H_Beatbox"
        break
      case 1:
        evt = "H_DJ"
        break
      case 2:
        evt = "H_Guitar"
        break
      case 3:
        evt = "H_Keyboard"
        break
      case 4:
        evt = "H_Vocal"
        break
      default:
        break;
    }
    this.handleTrackEvent(evt)
    this.setState(state => ({...state, value: newValue}))
    
  }

  handleResize = e => {
    const windowSize = window.innerWidth;
    this.setState(state => ({...state, isMobile: windowSize < 768}))
  }

  componentDidMount() {
    const windowSize = window.innerWidth;
    this.setState(state => ({...state, isMobile: windowSize < 768}))
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleTrackEvent(evt) {
    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('trackCustom', evt);
    }
  }

  nextVideo() {
    if (this.state.value < 4) {
      this.setState(state => ({...state, value: this.state.value + 1}));
    } else {
      this.setState(state => ({...state, value: 0}));
    }
  }
  
  handleButtonClick = (product) => {
    if (window.fbq != null) {
      window.fbq('trackCustom', 'OrderNowButton');
    }
    if (product === 'mb') {
      this.props.add_to_cart(MOBandObj);
    } else if (product === 'sb') {
      this.props.add_to_cart(SBoardObj);
    }
    
    navigate('/cart');
  }

  render() {
    const MBTab = (
      <div className={IndexStyle.heroText}>
        <ScrollAnimation animateIn='fadeInDown' animateOut="fadeOutDown" duration={0.5} animateOnce={false}>
          <h1 style={{fontSize: '3rem'}}>MO-Band</h1>
          <p style={{fontWeight: 500}}>Motion sensing MIDI controller on your wrist</p>
          {/* <button className={IndexStyle.orderButton} onClick={() => this.handleButtonClick('mb')}>Add to cart</button> */}
          <Link to="mo-band">
            <button className={IndexStyle.orderButton}>Learn more</button>
          </Link>
        </ScrollAnimation>
      </div>
    )

    const SBTab = (
        <div className={IndexStyle.heroText} id="heroText2">
          <ScrollAnimation animateIn='slideInDown' animateOut="slideOutDown" duration={0.5} animateOnce={false}>
            <h1 style={{fontSize: '3rem'}}>S-Board</h1>
            <p style={{fontWeight: 500, maxWidth: "500px"}}>Modular Effect Plugin (VST, AU, AAX, Standalone)</p>
            {/* <button className={IndexStyle.orderButton} onClick={() => this.handleButtonClick('sb')}>Add to cart</button> */}
            <Link to="s-board">
              <button className={IndexStyle.orderButton}>Learn more</button>
            </Link>
          </ScrollAnimation>
        </div>
      
    )

    const SMTab = (
      <div className={IndexStyle.heroText} id="heroText3">
        <ScrollAnimation animateIn='pulse' animateOut="fadeOut" duration={0.5} animateOnce={false}>
          <h1 style={{fontSize: '3rem'}}>S-Motion</h1>
          <p style={{fontWeight: 500, maxWidth: "500px"}}>Software for MO-Band, translates Motion into MIDI</p>
          <Link to="s-motion">
            <button className={IndexStyle.orderButton}>Learn more</button>
          </Link>
        </ScrollAnimation>
      </div>
    )
    return (
      <Layout location={this.props.location} title={'Sonic Instruments'} image={cover}>
        <SEO
          title='Home'
          image={cover}
          keywords={[`music`, `MO-Band`, `beatbox`, `MIDI`, `audio engineering`, `MIDI controller`]}
        />
          <div className={IndexStyle.containerOne} id="containerOne2">
              <div className={IndexStyle.bannerImgContainer}>
                {/* <img className={IndexStyle.bannerImg} src={cover} alt='Mo-Band'/> */}
                <div className={IndexStyle.heroImage} id="hero2">
                  {/* <div className={IndexStyle.heroTextContainer}> */}
                  {this.state.isMobile ? null : SBTab}
                  {/* </div> */}
                </div>
              </div>
              {this.state.isMobile ? SBTab : null}
          </div>
          <div className={IndexStyle.containerOne}>
              <div className={IndexStyle.bannerImgContainer}>
                {/* <img className={IndexStyle.bannerImg} src={cover} alt='Mo-Band'/> */}
                <div className={IndexStyle.heroImage}>
                  {/* <div className={IndexStyle.heroTextContainer}> */}
                  {this.state.isMobile ? null : MBTab}
                  {/* </div> */}
                </div>
              </div>
              {this.state.isMobile ? MBTab : null}
          </div>

          <div className={IndexStyle.containerOne} id="containerOne3">
              <div className={IndexStyle.bannerImgContainer}>
                {/* <img className={IndexStyle.bannerImg} src={cover} alt='Mo-Band'/> */}
                <div className={IndexStyle.heroImage} id="hero3">
                  {/* <div className={IndexStyle.heroTextContainer}> */}
                  {this.state.isMobile ? null : SMTab}
                  {/* </div> */}
                </div>
              </div>
              {this.state.isMobile ? SMTab : null}
          </div>

          <div className={IndexStyle.container}>
            
            {/* <div className={IndexStyle.containerOneWrapper}>
              <div className={IndexStyle.containerTwo}>
                <h3 style={{textAlign:"center", fontSize: "30px", textTransform: "uppercase", fontWeight:700, marginBottom: '32px'}}>Products</h3>
                <MOBandCard />
                <SBoardCard />
              </div>
            </div> */}
            {/* <div style={{textAlign:'center'}}>
              {this.state.isMobile ? <ScrollAnimation animateIn='fadeIn'>
                <div style={{marginTop: '3rem'}}><Testimonial isMobile={this.state.isMobile}/></div>
              </ScrollAnimation> : <div style={{marginTop: '3rem'}}><Testimonial isMobile={this.state.isMobile}/></div>}
              
            </div> */}

            {/* <div className={IndexStyle.tabs}>
              <Tabs style={{minWidth:'30px'}} value={this.state.value} variant="scrollable" scrollButtons="auto" onChange={this.handleChange}>
                  <Tab label="Beatbox" />
                  <Tab label="DJ" />
                  <Tab label="Guitar / Bass"/>
                  <Tab label="Keyboard"/>
                  <Tab label="Vocal"/>
              </Tabs>
            </div>
            <div className={IndexStyle.containerTwoWrapper}>
              <div className={IndexStyle.containerTwo}>
                <IndexTab value={this.state.value} nextVideo={this.nextVideo}/>
              </div>
            </div> */}
            
            <div className={IndexStyle.containerThreeWrapper}>
              <div className={IndexStyle.containerTwo}>
                <div className={IndexStyle.infoBlock}>
                  <img className={comLogo} src={comLogo} style={{maxWidth:400, display:'block', marginLeft:'auto', marginRight:'auto', width:'100%'}} alt='Sonic Instruments'/>
                  <br/>
                  <p className={IndexStyle.aboutText}>Our mission is to develop new technology to break limits of music and arts. We are devoted to making technologies accessible and inspiring to artists around the world. With our contribution, new artforms will emerge and existing artforms will become more expressive.</p>
                  <br/>
                  <button style={{display: "block", margin: "0 auto", marginTop: "2rem", marginBottom: "2rem"}}><Link to="about">Our Story</Link></button>
                </div>
              </div>
            </div>
        </div>
      </Layout>
    )
  }
}

const actionCreators = { add_to_cart }

export default connect(null, actionCreators)(Home)

